.container {
  width: 60%;
  padding: 12px 12px 12px 12px;
  margin: 0 auto;
  margin-top: 15px;
  background: #272727;
  border-radius: 4px;
}
label {
  font-size: 0.85em;
  margin-left: 12px;
  color: #ffffff;
}
input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border: 1px solid #ccc;
}

/* Add styles to the submit button */

input[type="submit"] {
  background: #eb0404;
  margin: 0 auto;
  outline: 0;
  color: white;
  border: 0;
  padding: 12px 24px;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
  position: relative;
  display: inline-block;
  text-align: center;
}
input[type="submit"]:focus {
  background: #eb0404;
  color: whitesmoke;
}
input[type="submit"]:hover {
  background: #f83838;
}
.center {
  text-align: center;
}
@media only screen and (min-width: 100px) and (max-width: 699px) {
  .container {
    width: 85%;
  }
}
.form-message {
  margin-top: 10px;
  padding: 12px;
  opacity: 0;
  transition: 0.2s ease;
  border-radius: 4px;
  background-color: seagreen;
  color: #ffffff;
  text-align: center;
}
